import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/navbar.tsx";
import Header from "../../components/Header/header.tsx";
import Footer from "../../components/Footer/footer.tsx";
import postsMapperService from "../../mapper/postsMapper.ts";
import Loading from "../../components/Loading/loading.tsx";

// Define a type for the post to ensure type safety
type Post = {
  id: number;
  title: string;
  href: string;
  description: string;
  date: string;
};

export default function Blog() {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    postsMapperService.mapPosts().then((mappedPosts) => {
      setPosts(mappedPosts);
    });
  }, []);

  if (posts.length === 0) return <Loading />; // Handle loading state

  return (
    <>
      <Helmet>
        <title>News | SmartGov</title>
      </Helmet>
      <Navbar />
      <Header title={"News"} description={"Latest Updates from Our Team"} />
      <div className="mb-12 bg-white py-12 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-gray-200 pt-10 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="flex max-w-xl flex-col items-start justify-between"
              >
                <div className="flex items-center gap-x-4 text-xs">
                  <time className="text-gray-500">{post.date}</time>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 line-clamp-2 text-lg font-semibold leading-8 text-gray-900 group-hover:text-gray-600">
                    <a href={"/news/" + post.id}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
